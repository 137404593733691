// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Sayfanın tamamını kaplayacak şekilde ayarlanır */
    font-size: 24px; /* Yazı büyüklüğü */
    font-weight: bold; /* Yazıyı kalın yapar */
  }
  